// Jobber Feature Flagging flag names
// Add here the new feature flag names that will correspond to the split.io dashboard
// Alphabetical order, and please follow the format below for easy traceability
// label = "tag" // owner: Team name or channel, JOB-XXXX (for feature flag cleanup ticket)
export enum SplitNames {
  AchDefaultPaymentPreferences = "ach_default_payment_preferences", // owner: Bookkeeping
  AchManualEntryEnabled = "ach_manual_entry_enabled", // owner: Invoicing
  AutomationsCustomBuilder = "automations_custom_builder", // owner: Automations
  CampaignAutomations = "campaign_automations", // owner: Campaigns
  CampaignsAutomationsV2 = "campaigns_automations_v2", // owner: Campaigns
  CampaignsImageUploads = "campaigns_image_uploads", // owner: Campaigns
  CommsDeleteSmsConversationOnline = "comms_delete_sms_conversation_online", // owner: Comms Systems
  CommsReassignSmsConversationOnline = "comms_reassign_sms_conversation_online", // owner: Comms Systems
  CommsEmailMarketingImageUpload = "comms_email_marketing_image_upload", // owner: Comms Systems
  CommsEmailMarketingUpsell = "comms_email_marketing_upsell", // owner: Campaigns
  CommsUkKycDpnRegistration = "comms_uk_kyc_dpn_registration", // owner: Comms Systems
  DataVizDevJobs = "data_viz_dev_jobs", // owner: Workday
  GmpAchAutopay = "gmp_ach_autopay", // owner: Invoicing
  JobsImportFlatFile = "jobs_import_flat_file", // owner: Retention, JOB-97740
  JobsImportGCal = "jobs_import_gcal", // owner: Retention, JOB-103630
  MultipleReplyTo = "comms_reply_to_multiple_email", // owner: Comms Experience

  QuoteDepositShownOnJob = "quote_deposit_shown_on_job", // owner: Bookkeeping
  SchedulingRecommendations = "scheduling_recommendations", // owner: Work Setup
  SolePropNew = "comms_sole_prop_new", // owner: Comms Experience
  SolePropUpgrade = "comms_sole_prop_upgrade", // owner: Comms Experience
  WalletAchManualEntry = "wallet_ach_manual_entry", // owner: Invoicing
  RequestLimitIncreaseButton = "request_limit_increase_button", // owner: Invoicing
  ProgressInvoiceCreate = "progress_invoice_create", // owner: Bookkeeping
  ExpansionSspupSingleCoreOnline = "expansion_sspup_single_core_online", // owner: Expansion, JOB-103075
  ExpansionMarketingToolsOnline = "expansion_marketing_tools_online", // owner: Expansion, JOB-104081
  ReactScheduleView = "react_schedule_view", // owner: Scheduling
  SchedulingAssistant = "scheduling_assistant_online", // owner: Scheduling
  MarketingSuitePurchase = "marketing_suite_addon", // owner: Subscription Infrastructure
  MarketingDashboardFullAccess = "marketing_dashboard_online_full_access", // owner: SC Intake
}
